const discountsimg = [
    {
        url: require("../img/DISCOUNTS/avene.jpg"),
        src: require("../img/DISCOUNTS/avene.jpg"),
        alt: 'Avene'
    },
    {
        url: require("../img/DISCOUNTS/klorane.jpg"),
        src: require("../img/DISCOUNTS/klorane.jpg"),
        alt: 'Klorane'
    },
    {
        url: require("../img/DISCOUNTS/bioderma.png"),
        src: require("../img/DISCOUNTS/bioderma.png"),
        alt: 'Bioderma'
    },
    {
        url: require("../img/DISCOUNTS/aderma.jpg"),
        src: require("../img/DISCOUNTS/aderma.jpg"),
        alt: 'Aderma'
    },
    {
        url: require("../img/DISCOUNTS/esthederm.png"),
        src: require("../img/DISCOUNTS/esthederm.png"),
        alt: 'Esthederm'
    }
]

export default discountsimg;